import { S3Server } from "config";
import {
  FONT_FAMILY_FORMAT,
  FONT_FAMILY_NAME,
  FONT_STYLE,
  FONT_WEIGHT,
  OTHER_FONT_FORMAT,
  SELECTORS,
} from "constants/common";

const getUrlS3 = (url) => {
  const urlBase = getUrlImgBase(url);
  if (!urlBase) {
    return "";
  }
  return `${S3Server}${urlBase}`;
};

const getUrlImgBase = (val) => {
  if (!val) {
    return "";
  }
  let result = val;
  const arrCheck = ["https://", "http://"];
  arrCheck.forEach((item) => {
    if (val.includes(item)) {
      const bodyLink = val.slice(item.length);
      result = bodyLink.slice(bodyLink.indexOf("/"));
    }
  });

  return result;
};

const generateFieldKey = () => {
  const time = new Date().getTime();
  const random = Math.round(Math.random() * 10 ** 15);
  return (time + random).toString();
};

const initCustomizedFont = (files) => {
  const arrFileFormat = files.map((file) => {
    const nameFile = file.toLowerCase();
    const isInvalidFormat =
      nameFile.includes(OTHER_FONT_FORMAT.type) &&
      !nameFile.includes(`${OTHER_FONT_FORMAT.type}?#`);
    const format =
      FONT_FAMILY_FORMAT.find((item) => nameFile.includes(item.type))?.format ||
      "";
    let fontWeight = FONT_WEIGHT.NORMAL;
    let fontStyle = FONT_STYLE.NORMAL;

    const numSlice = nameFile.slice(
      nameFile.lastIndexOf("-") + 1,
      nameFile.lastIndexOf(".")
    );
    const numFont = +numSlice;
    if (
      numSlice &&
      !isNaN(+numSlice) &&
      numFont >= FONT_WEIGHT.B_100 &&
      numFont <= FONT_WEIGHT.B_900
    ) {
      fontWeight = numFont;
    } else if (nameFile.includes(FONT_WEIGHT.BOLD)) {
      fontWeight = FONT_WEIGHT.BOLD;
    }

    if (nameFile.includes(FONT_STYLE.ITALIC)) {
      fontStyle = FONT_STYLE.ITALIC;
    }
    if (nameFile.includes(FONT_STYLE.OBLIQUE)) {
      fontStyle = FONT_STYLE.OBLIQUE;
    }
    return {
      src: file,
      format: isInvalidFormat ? "" : format,
      fontWeight,
      fontStyle,
    };
  });

  if (arrFileFormat) {
    const fileWithFormats = arrFileFormat.filter((f) => f.format);
    const arrMultiFormats = [];
    fileWithFormats.forEach((f) => {
      const idx = arrMultiFormats.findIndex(
        (fo) => fo.fontWeight === f.fontWeight && fo.fontStyle === f.fontStyle
      );
      if (idx !== -1) {
        arrMultiFormats[idx].fileFormat.push({ format: f.format, src: f.src });
      } else {
        arrMultiFormats.push({
          fontWeight: f.fontWeight,
          fontStyle: f.fontStyle,
          fileFormat: [{ src: f.src, format: f.format }],
        });
      }
    });
    const styleEl = document.createElement("style");
    styleEl.id = SELECTORS.STYLE_CUSTOMIZED_FONT_ID;
    arrMultiFormats.forEach((item) => {
      let strVal = "";
      item.fileFormat.forEach((sr, idx) => {
        strVal += `url(${sr.src}) format('${sr.format}')${
          idx === item.fileFormat.length - 1 ? ";" : ","
        }`;
      });
      styleEl.appendChild(
        document.createTextNode(
          "@font-face {font-family: " +
            FONT_FAMILY_NAME.CUSTOMIZED_FONT +
            ";font-style: " +
            item.fontStyle +
            ";font-weight: " +
            item.fontWeight +
            ";src:" +
            strVal +
            "}"
        )
      );
    });

    styleEl.appendChild(
      document.createTextNode(
        `* { font-family: ${FONT_FAMILY_NAME.CUSTOMIZED_FONT} !important; }`
      )
    );
    document.head.appendChild(styleEl);
  }
};

const getImageFileFromUrl = async (path, name) => {
  let response = await fetch(path);
  let data = await response.blob();

  return new File([data], name, {
    type: "image/png",
  });
};

const isInAppBrowser = () => {
  const userAgent = navigator.userAgent;
  return /webview|wv|ip((?!.*Safari)|(?=.*like Safari))/i.test(userAgent);
};

export {
  generateFieldKey,
  getImageFileFromUrl,
  getUrlImgBase,
  getUrlS3,
  initCustomizedFont,
  isInAppBrowser,
};
