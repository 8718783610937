import { chunk as _chunk, debounce as _debounce } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Preload from "react-preload";
import { withRouter } from "react-router";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import EntribeLogo from "../../../../assets/img/logo-black.png";
import facebookLogo from "../../../../assets/img/teampixel/gallery/facebook-logo.png";
import instagramLogo from "../../../../assets/img/teampixel/gallery/instagramlogo.png";
import img_1 from "../../../../assets/img/teampixel/gallery/teampixel-1.png";
import img_10 from "../../../../assets/img/teampixel/gallery/teampixel-10.png";
import img_11 from "../../../../assets/img/teampixel/gallery/teampixel-11.png";
import img_12 from "../../../../assets/img/teampixel/gallery/teampixel-12.jpg";
import img_13 from "../../../../assets/img/teampixel/gallery/teampixel-13.jpg";
import img_14 from "../../../../assets/img/teampixel/gallery/teampixel-14.jpg";
import img_15 from "../../../../assets/img/teampixel/gallery/teampixel-15.png";
import img_16 from "../../../../assets/img/teampixel/gallery/teampixel-16.jpg";
import img_2 from "../../../../assets/img/teampixel/gallery/teampixel-2.jpg";
import img_3 from "../../../../assets/img/teampixel/gallery/teampixel-3.png";
import img_4 from "../../../../assets/img/teampixel/gallery/teampixel-4.png";
import img_5 from "../../../../assets/img/teampixel/gallery/teampixel-5.jpg";
import img_6 from "../../../../assets/img/teampixel/gallery/teampixel-6.png";
import img_7 from "../../../../assets/img/teampixel/gallery/teampixel-7.jpg";
import img_8 from "../../../../assets/img/teampixel/gallery/teampixel-8.jpg";
import img_9 from "../../../../assets/img/teampixel/gallery/teampixel-9.jpg";
import xLogo from "../../../../assets/img/teampixel/gallery/x-logo.png";
import youtubeLogo from "../../../../assets/img/teampixel/gallery/youtubeLogo.png";
import animatedLogo from "../../../../assets/img/teampixel/logo-animation.gif";
import uploadFileType, {
  BRAND_NAME,
  DEFAULT_WIDGET_ID,
  DURATION,
  FIELD_NAME,
  GOOGLE_TRANSLATE,
  IN_CAPTURATE,
  IN_PROGRESS,
  NONE,
  SUCCEEDED,
  TYPE_CUSTOM_FIELD,
} from "../../../../constants/common";
import configUrls from "../../../../constants/configUrls";
import errorMsgs from "../../../../constants/errorMsgs";
import utils from "../../../../services/utils";
import {
  FIELD_KEYS,
  googleTerms,
  shotFeatureOptions,
  shotOnOptions,
} from "./data";

import { IconArrowDownFormGroup } from "assets/img/icons";
import _ from "lodash";
import { connect } from "react-redux";
import { getBrowserGeolocation } from "services/location";
import {
  convertUploadPayload,
  convertUploadPayloadToFormData,
} from "services/widgetBuilder";
import {
  createContentTypes,
  getGeolocationTypes,
  sendEmailTypes,
  storeDataTypes,
  uploadContentTypes,
} from "stores/widgets/widgets.types";
import { StyledIcon } from "styled-components/Common/CommonStyled";
import { StyledGoogleTranslateTP } from "styled-components/TeamPixelStyled";
import { Env, HomeUrl } from "../../../../config";
import GoogleTermsModal from "./GoogleTermsModal";
import StandardTermsModal from "./StandardTermsModal";
import "./TeamPixel.scss";
import UploadStatusList from "./UploadStatusList";
const loadingIndicator = (
  <div className="preloader-container">
    <img className="scale-up-center" src={animatedLogo} alt="EnTribe Logo" />
  </div>
);

const maxPreloadDelay = 10000;
const defaultUploadBtnTxt = "Upload";
const imageGallery = [
  img_1,
  img_2,
  img_3,
  img_4,
  img_5,
  img_6,
  img_7,
  img_8,
  img_9,
  img_10,
  img_11,
  img_12,
  img_13,
  img_14,
  img_15,
  img_16,
];
const MODAL_TYPE = {
  GOOGLE_TERMS: "GOOGLE_TERMS",
  STANDARD_TERMS: "STANDARD_TERMS",
};
class TeamPixel extends Component {
  static propTypes = {
    globalProps: PropTypes.shape({
      setIsShowLoading: PropTypes.func,
      requestAPI: PropTypes.func,
      didMountCallback: PropTypes.func,
    }),
  };

  static defaultProps = {
    globalProps: {
      setIsShowLoading: () => {},
      requestAPI: () => {},
      didMountCallback: () => {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabledWidget: false,
      formState: 2, // 1 - Welcome, 2 - Upload, 3 - Thankyou

      client: null,
      challenge: null,
      selectId: null,

      selectedHashtags: ["#teampixel"],

      email: "",
      firstName: "",
      lastName: "",
      instagramUsername: "",

      isValidEmail: true,
      isValidFirstName: true,
      isValidLastName: true,
      isValidInstagram: true,
      isValidAboutImg: true,
      isValidByPixel: true,
      isValidCameraFeature: true,
      isValidCapturedPlace: true,

      emailErrMsg: "",
      firstNameErrMsg: "",
      lastNameErrMsg: "",
      instagramErrMsg: "",
      descriptionErrMsg: "",
      shotOnErrMsg: "",
      shotFeaturesErrMsg: "",
      shotLocationErrMsg: "",

      isAgreed: false,
      isAgreeGoogleTerms: false,
      isShowGoogleTerms: false,

      errorMsg: "",
      uploadedFiles: [],
      uploadedFileType: "",
      previewTxt: "",
      previewImg: "",

      uploadResults: null,

      shotFeatures: [],
      shotOn: [],

      // Use to calculate and render text with typing animation
      subTitle: "Hello, #TeamPixel.",
      subTitleIndex: 0,
      displaySubTitle: "",
      subHeader:
        "We need your permission to use this photo/video. Complete the fields below, submit the form, and we're all set!",
      subHeaderClass: "sub-header",

      // Prevent browser cache for GIF image
      logoId: 0,

      chunkImages_1: [],
      chunkImages: [],

      uploadBtnText: defaultUploadBtnTxt,
      uploadPayloadList: [],
      isShowGoogleTranslate: false,
      modalType: "",
    };

    this.input = {
      email: React.createRef(),
      firstName: React.createRef(),
      lastName: React.createRef(),
      instagramUsername: React.createRef(),
      twitterUsername: React.createRef(),
      tiktokUsername: React.createRef(),
      description: React.createRef(),
      shotOn: React.createRef(),
      shotFeatures: React.createRef(),
      shotLocation: React.createRef(),
      files: React.createRef(),
      isAgreed: React.createRef(),
      isAgreeGoogleTerms: React.createRef(),
    };
  }

  componentDidMount() {
    const {
      globalProps: { didMountCallback },
    } = this.props;

    let { subHeaderClass } = this.state;
    subHeaderClass += " sub-header-animation";

    this.initGoogleTranslate();
    didMountCallback();

    // Play text animation after the logo end animating
    setTimeout(() => {
      this.animateTxt();
      this.setState({
        subHeaderClass,
        isShowGoogleTranslate: true,
      });
    }, DURATION.S_2);
  }

  initGoogleTranslate = () => {
    utils.googleTranslateScript(GOOGLE_TRANSLATE.TEAMPIXEL_ID);
  };

  componentWillReceiveProps(nextProps) {
    const { client = null, challenge = null, selectId = null } = nextProps;

    this.checkChallenge(challenge);
    this.setState({
      client,
      selectId,
    });
  }

  componentWillMount() {
    this.setOnChangeEmailEvent();
    this.setState({ logoId: Math.random(), isShowGoogleTranslate: false });
    const { selectId = null, challengeId = null } = this.props;

    if (challengeId && Env === "prodv2") {
      const migratedCampaignId = utils.mgratedCampaignIdInV2(
        "prod",
        "teampixel",
        challengeId
      );

      if (challengeId != migratedCampaignId) {
        let url = `${HomeUrl}/teampixel?c=${migratedCampaignId}`;
        if (selectId) {
          url = `${url}&s=${selectId}`;
        }
        window.location.href = url;
        return;
      }
    }
  }

  componentDidUpdate(prevProps, _) {
    const { widgets: prevWidgets } = prevProps;

    this.handleUploadFlow(prevWidgets);
    this.uploadAfterGetLocation(prevWidgets);
  }

  uploadAfterGetLocation(prevWidgets) {
    const { widgets } = this.props;
    const { getLocationStatus = NONE } = widgets;

    if (
      prevWidgets.getLocationStatus &&
      prevWidgets.getLocationStatus !== getLocationStatus
    ) {
      if (getLocationStatus === SUCCEEDED) {
        this.uploadAPI();
        this.props.getGeoLocationEnd();
      }
    }
  }

  handleUploadFlow(prevWidgets) {
    const { widgets } = this.props;
    const {
      createContentStatus,
      createdContent,
      fileProgress,
      uploadedContentList,
      createdContentError,
    } = widgets;
    const {
      globalProps: { setIsShowLoading },
    } = this.props;

    if (prevWidgets.createContentStatus !== createContentStatus) {
      if (createContentStatus === IN_PROGRESS) {
        setIsShowLoading(true);
      }
      if (createContentStatus === SUCCEEDED && createdContent) {
        this.uploadContent(createdContent);
      }
    }

    if (
      prevWidgets !== widgets &&
      prevWidgets.fileProgress !== fileProgress &&
      !!fileProgress.length
    ) {
      let isInProgress = true;
      let fileInSucceed = [];
      isInProgress = fileProgress.some(
        (item) => item.status === IN_PROGRESS || item.status === IN_CAPTURATE
      );
      fileInSucceed = fileProgress.filter((item) => item.status === SUCCEEDED);

      if (!isInProgress) {
        if (
          uploadedContentList.length &&
          fileInSucceed.length === uploadedContentList.length
        ) {
          this.handleSendConfirmationEmail();
        }
        setIsShowLoading(false);
        this.setState({
          formState: 3,
        });
      }
    }

    if (
      createdContentError &&
      createdContentError !== prevWidgets.createdContentError
    ) {
      setIsShowLoading(false);
      this.handleErrorMsg(createdContentError);
      this.props.storeDataRequest({
        createdContentError: null,
      });
    }
  }

  handleErrorMsg(errorMsg) {
    // Clear any existing timeout
    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
    }

    this.setState({
      errorMsg,
    });

    this.errorTimeout = setTimeout(() => {
      this.setState({
        errorMsg: "",
      });

      // Clear the timeout reference
      this.errorTimeout = null;
    }, DURATION.S_10);
  }

  uploadContent(createdContent) {
    const { uploadPayloadList, client } = this.state;
    const { uploadContentRequest, challengeId: campaignId } = this.props;
    const widgetInfo = {
      id: DEFAULT_WIDGET_ID,
      clientId: client.id,
    };

    const contentList = convertUploadPayload(
      createdContent,
      widgetInfo,
      campaignId,
      uploadPayloadList
    );

    if (contentList.length) {
      contentList.forEach((content) => {
        uploadContentRequest({
          ...content,
          onUploadProgress: () => {},
          widgetInfo,
          isNewFlow: true,
        });
      });
    }
  }

  handleSendConfirmationEmail() {
    const { email, challenge } = this.state;
    const { uploadedContentList } = this.props.widgets;
    const payloadSendEmail = {
      email,
      requestIds: [],
      termsIds: [1, 2],
      widgetUrl: window.location.href,
    };
    if (uploadedContentList.length) {
      payloadSendEmail.requestIds = uploadedContentList.map(
        (item) => item.requestId
      );
    }
    if (challenge && challenge.id) {
      payloadSendEmail.campaignId = challenge.id;
    }
    this.props.sendEmailRequest(payloadSendEmail);
  }

  checkChallenge(challenge) {
    const { selectId = null, client = null } = this.props;

    if (challenge) {
      this.setState({ challenge, isDisabledWidget: false, errorMsg: "" });

      // Only validate challenge and show error if selectId is empty
    } else if (!challenge && !selectId && !client) {
      this.setState({
        isDisabledWidget: true,
        errorMsg: "Invalid data",
      });
    }
  }

  setOnChangeEmailEvent() {
    this.onChangeEmailCallback = _debounce((e) => {
      const email = this.getInputValue("email");

      if (email && utils.validateEmail(email)) {
        this.getCreatorAPI(email);
      } else {
        // TODO, maybe use later
        // this.resetAutoFilledFields();
      }
    }, 1000);
  }

  getCreatorAPI = (email) => {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const successCallback = (resp) => {
      const {
        firstName = "",
        lastName = "",
        instagramUsername = "",
        twitterUsername = "",
        tiktokUsername = "",
      } = resp || {};

      this.setState({
        firstName,
        lastName,
        instagramUsername,
        twitterUsername,
        tiktokUsername,
        errorMsg: "",
        firstNameErrMsg: "",
        lastNameErrMsg: "",
        emailErrMsg: "",
        instagramErrMsg: "",
      });
    };

    const failedCallback = (resp) => {
      const errorMsg = resp.error
        ? resp.error
        : resp.status
        ? resp.status.status
        : errorMsgs["en"].callAPIFailed;
      this.setState({ errorMsg });
      this.resetAutoFilledFields();
    };
    const clientId = sessionStorage.getItem("clientId");
    const url = `${configUrls.API.getCreator}?clientId=${clientId}&email=${email}`;
    requestAPI("get", url, {}, successCallback, failedCallback);
  };

  uploadAPI() {
    const {
      client: { id: clientId },
      challenge,
      selectId,
      uploadedFiles,
      uploadedFileType,
      selectedHashtags,
      shotFeatures: shotFeaturesInfo,
      shotOn: shotOnInfo,
    } = this.state;
    const { uploadLocation } = this.props.widgets;

    const parsedUploadedFiles = [];
    const shotOn = {
      name: "Shot On",
      values: [shotOnInfo.value],
      isTaggable: true,
      isSearchable: true,
      type: TYPE_CUSTOM_FIELD.dropdown,
      key: FIELD_KEYS.SHOT_ON,
    };
    const shotLocation = {
      name: "Shot Location",
      values: this.getInputValue("shotLocation"),
      isTaggable: false,
      isSearchable: true,
      type: TYPE_CUSTOM_FIELD.paragraph,
      key: FIELD_KEYS.SHOT_LOCATION,
    };
    const shotFeatures = {
      name: "Shot Features",
      values: shotFeaturesInfo.map((item) => item.value),
      isTaggable: true,
      isSearchable: true,
      type: TYPE_CUSTOM_FIELD.dropdown,
      key: FIELD_KEYS.SHOT_FEATURES,
    };
    const description = {
      name: "Description",
      values: this.getInputValue("description"),
      isTaggable: false,
      isSearchable: true,
      type: TYPE_CUSTOM_FIELD.paragraph,
      key: FIELD_KEYS.DESCRIPTION,
    };

    const additional = {
      custom: [shotOn, shotLocation, shotFeatures, description],
    };

    const contentCustomProperties = additional.custom || [];

    for (let i = 0; i < uploadedFiles.length; i++) {
      parsedUploadedFiles.push(uploadedFiles[i]);
    }

    const data = {
      widgetId: DEFAULT_WIDGET_ID,
      clientId,
      selectId,
      challengeId: (challenge && challenge.id) || 0,
      uploadedFiles: parsedUploadedFiles,
      uploadedFileType,
      selectedHashtags,
      email: this.getInputValue("email"),
      firstName: this.getInputValue("firstName"),
      lastName: this.getInputValue("lastName"),
      instagramUsername: this.getInputValue("instagramUsername"),
      twitterUsername: this.getInputValue("twitterUsername"),
      tiktokUsername: this.getInputValue("tiktokUsername"),
      description: this.getInputValue("description"),
      additional,
      termsIds: [1, 2],
      termsTemplateId: 1,
      brandName: BRAND_NAME.REVERE,
      contentCustomProperties,
      uploadWidgetLink: window.location.href,
      hasCustomTerms: true,
      hasEntribeStandardTerms: true,
    };

    if (uploadLocation && !_.isEmpty(uploadLocation)) {
      data.ipStack = uploadLocation;
      data.location = uploadLocation;
    }

    const parsedData = utils.parseUploadData(data);
    this.setState(
      {
        uploadPayloadList: convertUploadPayloadToFormData(
          parsedData,
          uploadedFiles,
          uploadLocation
        ),
      },
      () => {
        this.props.createContentRequest(parsedData);
      }
    );
  }

  onSubmit = async () => {
    const { uploadedFiles } = this.state;
    const isValidInput = this.validateForm();
    const isValidUploadedFiles = await this.validateUploadedFiles(
      uploadedFiles
    );
    const { isAgreed, isAgreeGoogleTerms } = this.state;
    const { uploadLocation } = this.props.widgets;
    const isExistUploadLocation = uploadLocation && !_.isEmpty(uploadLocation);

    if (
      isValidInput &&
      isValidUploadedFiles &&
      isAgreed &&
      isAgreeGoogleTerms
    ) {
      getBrowserGeolocation(
        (position) => {
          isExistUploadLocation
            ? this.uploadAPI()
            : this.props.getGeoLocationRequest(position);
        },
        () => this.uploadAPI()
      );
    }
  };

  onChangeEmail = (e) => {
    if (e.keyCode === 13) {
      // Enter key
      this.onSubmit();
      return;
    }

    e.persist();
    this.onChangeEmailCallback(e);
    this.setState({ email: e.target.value });
  };

  onChangeInput = (e, name) => {
    this.setState({ [name]: e.target.value });
  };

  onToggleAgreement = (e) => {
    const isAgreed = e.target.checked;
    this.input.isAgreed.current.value = isAgreed ? "on" : "off";
    this.setState({ isAgreed });
  };

  onToggleGoogleTerms = (e) => {
    const isAgreeGoogleTerms = e.target.checked;
    this.input.isAgreeGoogleTerms.current.value = isAgreeGoogleTerms
      ? "on"
      : "off";
    this.setState({ isAgreeGoogleTerms });
  };

  handleTermsModal = (modalType) => {
    this.setState({
      modalType,
    });
  };

  onEnter = (e) => {
    if (e.keyCode === 13) {
      // Enter key
      this.onSubmit();
    }
  };

  onChangeSelect = (e, name) => {
    this.setState({ [name]: e });
  };

  getInputValue = (name) => {
    if (!name) {
      return "";
    }

    return this.input[name].current ? this.input[name].current.value : "";
  };

  validateInput = (data) => {
    const {
      email = "",
      firstname = "",
      lastName = "",
      instagramUsername = "",
      description = "",
      shotOn = [],
      shotFeatures = [],
      shotLocation = "",
    } = data;

    let isValidForm = true;

    let isValidEmail = true;
    let isValidFirstName = true;
    let isValidLastName = true;
    let isValidInstagram = true;
    let isValidAboutImg = true;
    let isValidByPixel = true;
    let isValidCameraFeature = true;
    let isValidCapturedPlace = true;

    let emailErrMsg = "";
    let firstNameErrMsg = "";
    let lastNameErrMsg = "";
    let instagramErrMsg = "";
    let descriptionErrMsg = "";
    let shotOnErrMsg = "";
    let shotFeaturesErrMsg = "";
    let shotLocationErrMsg = "";

    if (!email) {
      isValidForm = false;
      isValidEmail = false;
      emailErrMsg = errorMsgs["en"].requiredEmail;
    } else {
      if (!utils.validateEmail(email)) {
        isValidForm = false;
        isValidEmail = false;
        emailErrMsg = errorMsgs["en"].invalidEmail;
      } else {
        isValidEmail = true;
      }
    }

    if (!firstname) {
      isValidForm = false;
      isValidFirstName = false;
      firstNameErrMsg = errorMsgs["en"].requiredFirstName;
    } else {
      isValidFirstName = true;
    }

    if (!lastName) {
      isValidForm = false;
      isValidLastName = false;
      lastNameErrMsg = errorMsgs["en"].requiredLastname;
    } else {
      isValidLastName = true;
    }

    if (!instagramUsername) {
      isValidForm = false;
      isValidInstagram = false;
      instagramErrMsg = "(!) Please enter Instagram username.";
    } else {
      isValidInstagram = true;
    }

    if (!description) {
      isValidForm = false;
      isValidAboutImg = false;
      descriptionErrMsg = "(!) Please tell us about the image.";
    } else {
      isValidAboutImg = true;
    }

    if (shotOn.length === 0) {
      isValidForm = false;
      isValidByPixel = false;
      shotOnErrMsg =
        "(!) Please share us which Pixel you photographed this with.";
    } else {
      isValidByPixel = true;
    }

    if (shotFeatures.length === 0) {
      isValidForm = false;
      isValidCameraFeature = false;
      shotFeaturesErrMsg =
        "(!) Please tell us what camera feature you used to photograph with this.";
    } else {
      isValidCameraFeature = true;
    }

    if (!shotLocation) {
      isValidForm = false;
      isValidCapturedPlace = false;
      shotLocationErrMsg =
        "(!) Please tell us where this was photographed (Country/city).";
    } else {
      isValidCapturedPlace = true;
    }

    return {
      isValidForm, // validate success or failed

      isValidEmail,
      isValidFirstName,
      isValidLastName,
      isValidInstagram,
      isValidAboutImg,
      isValidByPixel,
      isValidCameraFeature,
      isValidCapturedPlace,

      emailErrMsg,
      firstNameErrMsg,
      lastNameErrMsg,
      instagramErrMsg,
      descriptionErrMsg,
      shotOnErrMsg,
      shotFeaturesErrMsg,
      shotLocationErrMsg,
    };
  };

  validateForm = () => {
    const { shotFeatures, shotOn } = this.state;

    const email = this.getInputValue("email");
    const firstname = this.getInputValue("firstName");
    const lastName = this.getInputValue("lastName");
    const instagramUsername = this.getInputValue("instagramUsername");
    const description = this.getInputValue("description");
    const shotLocation = this.getInputValue("shotLocation");

    const data = {
      email,
      firstname,
      lastName,
      instagramUsername,
      description,
      shotOn,
      shotFeatures,
      shotLocation,
    };

    const {
      isValidForm,

      isValidEmail,
      isValidFirstName,
      isValidLastName,
      isValidInstagram,
      isValidAboutImg,
      isValidByPixel,
      isValidCameraFeature,
      isValidCapturedPlace,

      emailErrMsg,
      firstNameErrMsg,
      lastNameErrMsg,
      instagramErrMsg,
      descriptionErrMsg,
      shotOnErrMsg,
      shotFeaturesErrMsg,
      shotLocationErrMsg,
    } = this.validateInput(data);

    this.setState({
      isValidEmail,
      isValidFirstName,
      isValidLastName,
      isValidInstagram,
      isValidAboutImg,
      isValidByPixel,
      isValidCameraFeature,
      isValidCapturedPlace,

      emailErrMsg,
      firstNameErrMsg,
      lastNameErrMsg,
      instagramErrMsg,
      descriptionErrMsg,
      shotOnErrMsg,
      shotFeaturesErrMsg,
      shotLocationErrMsg,
    });

    return isValidForm;
  };

  resetForm = () => {
    this.setState({ ...utils.getEmptyFormData() });
  };

  resetAutoFilledFields = () => {
    this.setState({
      firstName: "",
      lastName: "",
      instagramUsername: "",
      twitterUsername: "",
      tiktokUsername: "",
    });
  };

  validateUploadedFiles = async (files) => {
    const { errorMsg, isValidFiles, previewImg, previewTxt } =
      await utils.validateUploadedFiles(files);
    if (!isValidFiles) {
      this.setState({
        uploadedFiles: [],
        errorMsg,
        previewImg,
        previewTxt,
      });

      return false;
    }

    this.setState({ uploadedFiles: files, errorMsg });
    return true;
  };

  renderPreviewTxt = (files) => {
    this.setState({ previewTxt: utils.getPreviewTxt(files) });
  };

  renderChildImageGroup = (arr, direction) => {
    const chunksCell = this.separateImages(arr, 4); //split into 4 cells, making 4 arrays with 4 items;
    return (
      <div className="image-holder">
        <div className={`images ${direction}`}>
          {chunksCell.map((photo, index) => {
            return (
              <img
                className="img1"
                src={photo}
                alt="Google pixel gallery"
                key={index}
              />
            );
          })}
        </div>
      </div>
    );
  };

  renderBlockChildImage = (arr, index) => {
    const chunksBlock = this.separateImages(arr, 4); //split into 4 blocks, making 4 arrays with 4 items;

    return (
      <div key={index} className="block-child">
        {this.renderChildImageGroup(chunksBlock[0], "clockwise")}
        {this.renderChildImageGroup(chunksBlock[1], "counterClockwise")}
        {this.renderChildImageGroup(chunksBlock[2], "counterClockwise")}
        {this.renderChildImageGroup(chunksBlock[3], "clockwise")}
      </div>
    );
  };

  renderMobileBlockChildImage = (arr, index) => {
    const chunksBlock = this.separateImages(arr, 4); //split into 4 blocks, making 4 arrays with 4 items;

    return (
      <div key={index}>
        <div className="block-child">
          {this.renderChildImageGroup(chunksBlock[0], "clockwise")}
          {this.renderChildImageGroup(chunksBlock[1], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[2], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[3], "clockwise")}
        </div>
        <div className="block-child">
          {this.renderChildImageGroup(chunksBlock[1], "clockwise")}
          {this.renderChildImageGroup(chunksBlock[2], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[3], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[0], "clockwise")}
        </div>
        <div className="block-child">
          {this.renderChildImageGroup(chunksBlock[2], "clockwise")}
          {this.renderChildImageGroup(chunksBlock[3], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[0], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[1], "clockwise")}
        </div>
        <div className="block-child">
          {this.renderChildImageGroup(chunksBlock[3], "clockwise")}
          {this.renderChildImageGroup(chunksBlock[0], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[1], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[2], "clockwise")}
        </div>
        <div className="block-child">
          {this.renderChildImageGroup(chunksBlock[0], "clockwise")}
          {this.renderChildImageGroup(chunksBlock[1], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[2], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[3], "clockwise")}
        </div>
        <div className="block-child">
          {this.renderChildImageGroup(chunksBlock[1], "clockwise")}
          {this.renderChildImageGroup(chunksBlock[2], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[3], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[0], "clockwise")}
        </div>
      </div>
    );
  };

  populateImageArray = (arr) => {
    while (arr.length < 64) {
      let clonedArr = [...arr];
      arr.forEach((item) => {
        if (clonedArr.length < 64) {
          clonedArr.push(item);
        }
      });
      arr = [...clonedArr];
    }

    return arr;
  };

  separateImages = (arr = [], size) => {
    let result = Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );

    return result.length > 1 ? result : result[0];
  };

  handleListPhotos = (images) => {
    if (images.length < 64) {
      images = this.populateImageArray(images);
    } else if (images.length > 64) {
      let spares = images.length - 64;
      images.splice(64, spares);
    }

    return this.separateImages(images, 16);
  };

  animateTxt = () => {
    const { subTitleIndex, subTitle } = this.state;

    let { displaySubTitle } = this.state;

    if (subTitleIndex < subTitle.length) {
      displaySubTitle += subTitle.charAt(subTitleIndex);
      this.setState(
        { displaySubTitle, subTitleIndex: subTitleIndex + 1 },
        () => {
          setTimeout(() => this.animateTxt(), 160);
        }
      );
    }
  };

  selectFiles = (e) => {
    this.input.files.current.click();
  };

  onUploadFiles = async (e) => {
    const {
      globalProps: { setIsShowLoading },
    } = this.props;
    let selectedFiles = this.input.files.current.files;
    const countSelectedFiles = selectedFiles.length;

    selectedFiles = _.toArray(selectedFiles);
    selectedFiles = await utils.convertFileUploads(
      selectedFiles,
      setIsShowLoading
    );

    utils.getPreviewImgAndType(selectedFiles).then((data) => {
      const { uploadedFileType } = data;
      this.setState({ uploadedFileType });
    });

    if (
      countSelectedFiles > 0 &&
      (await this.validateUploadedFiles(selectedFiles))
    ) {
      this.setState({
        uploadedFiles: selectedFiles,
        uploadBtnText: `Selected ${selectedFiles.length} file(s)`,
      });
    } else {
      this.setState({ uploadedFiles: [], uploadBtnText: defaultUploadBtnTxt });
    }
  };

  renderGallery = (imageGallery, isMobile = false) => {
    return (
      <div className="block-parent-wrapper">
        <div className="block-parent">
          {!isMobile &&
            imageGallery.map((arr, index) =>
              this.renderBlockChildImage(arr, index)
            )}
          {isMobile &&
            imageGallery.map((arr, index) =>
              this.renderMobileBlockChildImage(arr, index)
            )}
        </div>
      </div>
    );
  };

  renderGoogleTranslate() {
    return (
      <StyledGoogleTranslateTP id={GOOGLE_TRANSLATE.TEAMPIXEL_ID}>
        {this.state.isShowGoogleTranslate && (
          <StyledIcon color="hsl(0, 0%, 80%)">
            <IconArrowDownFormGroup />
          </StyledIcon>
        )}
      </StyledGoogleTranslateTP>
    );
  }

  render() {
    const {
      displaySubTitle,
      isDisabledWidget,
      formState,
      isAgreed,
      isAgreeGoogleTerms,
      errorMsg,
      logoId,

      email,
      firstName,
      lastName,
      instagramUsername,
      twitterUsername,
      tiktokUsername,
      shotFeatures,
      shotOn,
      emailErrMsg,
      firstNameErrMsg,
      lastNameErrMsg,
      instagramErrMsg,
      descriptionErrMsg,
      shotOnErrMsg,
      shotFeaturesErrMsg,
      shotLocationErrMsg,

      isValidEmail,
      isValidFirstName,
      isValidLastName,
      isValidInstagram,
      isValidAboutImg,
      isValidByPixel,
      isValidCameraFeature,
      isValidCapturedPlace,

      uploadBtnText,
      subHeader,
      subHeaderClass,
      modalType,
    } = this.state;

    let IGPrefixSymbol = "input-group-text";
    const widgetContainerClasses = isDisabledWidget
      ? "widget-container disabled"
      : "widget-container";
    const logoStyles = {
      backgroundImage: `url(${animatedLogo}?${logoId})`,
    };

    // Create an empty array with 4 empty array item: [[], [], [], []];
    const chunkImages = [[], [], [], []];
    // Get distinced images from the gallery, separate into 4 child arrays: [[1,2,3,4], [5,6,7,8], [9,10,11,12], [13,14,15,6]]
    const chunkImagesTemplate = _chunk(
      this.handleListPhotos(imageGallery)[0],
      4
    );

    for (let i = 0; i < 4; i++) {
      chunkImagesTemplate.forEach((images) => {
        if (i === 0) {
          chunkImages[i].push(images[0]);
          chunkImages[i].push(images[1]);
          chunkImages[i].push(images[2]);
          chunkImages[i].push(images[3]);
        } else if (i === 1) {
          chunkImages[i].push(images[1]);
          chunkImages[i].push(images[2]);
          chunkImages[i].push(images[3]);
          chunkImages[i].push(images[0]);
        } else if (i === 2) {
          chunkImages[i].push(images[2]);
          chunkImages[i].push(images[3]);
          chunkImages[i].push(images[0]);
          chunkImages[i].push(images[1]);
        } else {
          chunkImages[i].push(images[3]);
          chunkImages[i].push(images[0]);
          chunkImages[i].push(images[1]);
          chunkImages[i].push(images[2]);
        }
      });
    }

    return (
      <Preload
        loadingIndicator={loadingIndicator}
        images={imageGallery}
        autoResolveDelay={maxPreloadDelay}
        resolveOnError={true}
        mountChildren={true}
      >
        <div className={`widget-teampixel ${widgetContainerClasses}`}>
          <div className="container">
            <div className="mobile-gallery-container">
              <div className="gallery">
                {this.renderGallery(chunkImages, true)}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7">
                {formState === 2 && (
                  <div className="widget-block widget-upload">
                    <div className="widget-header">
                      <div className="logo" style={logoStyles}></div>
                      <h2 translate="no">{displaySubTitle}</h2>
                      <div className={subHeaderClass}>{subHeader}</div>
                    </div>
                    {/* End Header */}

                    <div className="widget-body">
                      <div className="widget-form">
                        <div className="error-msg">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: errorMsg,
                            }}
                          />
                        </div>
                        <div className="form-group">
                          {this.renderGoogleTranslate()}
                          <input
                            type="email"
                            className="form-control form-control-sm"
                            ref={this.input.email}
                            onKeyDown={(e) => this.onEnter(e)}
                            onChange={(e) => this.onChangeEmail(e)}
                            placeholder="Email*"
                            value={email}
                            name={FIELD_NAME.email}
                          />
                          {!isValidEmail && <small>{emailErrMsg}</small>} &nbsp;
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            ref={this.input.firstName}
                            onKeyDown={(e) => this.onEnter(e)}
                            onChange={(e) => this.onChangeInput(e, "firstName")}
                            placeholder="First Name*"
                            value={firstName}
                            name={FIELD_NAME.firstName}
                          />
                          {!isValidFirstName && (
                            <small>{firstNameErrMsg}</small>
                          )}
                          &nbsp;
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            ref={this.input.lastName}
                            onKeyDown={(e) => this.onEnter(e)}
                            onChange={(e) => this.onChangeInput(e, "lastName")}
                            placeholder="Last Name*"
                            value={lastName}
                            name={FIELD_NAME.lastName}
                          />
                          {!isValidLastName && <small>{lastNameErrMsg}</small>}
                          &nbsp;
                        </div>
                        <div className="form-group">
                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span className={IGPrefixSymbol}>@</span>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              ref={this.input.instagramUsername}
                              onKeyDown={(e) => this.onEnter(e)}
                              onChange={(e) =>
                                this.onChangeInput(e, "instagramUsername")
                              }
                              placeholder="Instagram*"
                              value={instagramUsername}
                              name={FIELD_NAME.instagram}
                            />
                          </div>
                          {!isValidInstagram && (
                            <small>{instagramErrMsg}</small>
                          )}
                          &nbsp;
                        </div>
                        <div className="form-group">
                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span className="input-group-text">@</span>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              ref={this.input.twitterUsername}
                              onKeyDown={(e) => this.onEnter(e)}
                              onChange={(e) =>
                                this.onChangeInput(e, "twitterUsername")
                              }
                              placeholder="X"
                              value={twitterUsername}
                              name={FIELD_NAME.twitter}
                            />
                          </div>
                          &nbsp;
                        </div>
                        <div className="form-group">
                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span className="input-group-text">@</span>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              ref={this.input.tiktokUsername}
                              onKeyDown={(e) => this.onEnter(e)}
                              onChange={(e) =>
                                this.onChangeInput(e, "tiktokUsername")
                              }
                              placeholder="TikTok"
                              value={tiktokUsername}
                              name={FIELD_NAME.tiktok}
                            />
                          </div>
                          &nbsp;
                        </div>
                        <div className="form-group">
                          <textarea
                            className="form-control form-control-sm"
                            ref={this.input.description}
                            placeholder="Tell us about the image. Who/what is pictured? Why is this photo important to you? The more detail, the better!*"
                            rows="3"
                          ></textarea>
                          {!isValidAboutImg && (
                            <small>{descriptionErrMsg}</small>
                          )}
                          &nbsp;
                        </div>
                        <div className="form-group">
                          <Select
                            classNamePrefix="react-select"
                            options={shotOnOptions}
                            onChange={(e) => this.onChangeSelect(e, "shotOn")}
                            value={shotOn}
                            placeholder="Which Pixel did you photograph this with?*"
                          />
                          {!isValidByPixel && <small>{shotOnErrMsg}</small>}
                          &nbsp;
                        </div>
                        <div className="form-group">
                          <CreatableSelect
                            isMulti
                            classNamePrefix="react-select"
                            options={shotFeatureOptions}
                            onChange={(e) =>
                              this.onChangeSelect(e, "shotFeatures")
                            }
                            value={shotFeatures}
                            placeholder="What camera feature did you use?*"
                            formatCreateLabel={() => "Input Other"}
                          />
                          {!isValidCameraFeature && (
                            <small>{shotFeaturesErrMsg}</small>
                          )}
                          &nbsp;
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            ref={this.input.shotLocation}
                            onKeyDown={(e) => this.onEnter(e)}
                            placeholder="Where was this photographed? (Country/city)*"
                          />
                          {!isValidCapturedPlace && (
                            <small>{shotLocationErrMsg}</small>
                          )}
                          &nbsp;
                        </div>
                      </div>
                      {/* End Input Fields */}

                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={(e) => this.selectFiles(e)}
                        title={utils.getFileUploadToolTip()}
                      >
                        {uploadBtnText}
                      </button>
                      <input
                        type="file"
                        multiple={true}
                        accept={uploadFileType}
                        ref={this.input.files}
                        onChange={(e) => this.onUploadFiles(e)}
                      />
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="widget-agreement">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="on"
                                id="agreement"
                                checked={isAgreed}
                                ref={this.input.isAgreed}
                                onChange={(e) => {
                                  this.onToggleAgreement(e);
                                }}
                              />
                              <label className="form-check-label">
                                I agree to the{" "}
                              </label>
                              <a
                                className="standard-terms"
                                onClick={() =>
                                  this.handleTermsModal(
                                    MODAL_TYPE.STANDARD_TERMS
                                  )
                                }
                              >
                                <u>Terms & Conditions*</u>
                              </a>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="on"
                                id="google_terms"
                                checked={isAgreeGoogleTerms}
                                ref={this.input.isAgreeGoogleTerms}
                                onChange={(e) => {
                                  this.onToggleGoogleTerms(e);
                                }}
                              />
                              <label className="form-check-label ">
                                I agree to the{" "}
                              </label>
                              <a
                                className="google-terms"
                                onClick={() =>
                                  this.handleTermsModal(MODAL_TYPE.GOOGLE_TERMS)
                                }
                              >
                                Google Content Licensing Terms*
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* End Agree Checkbox */}
                      </div>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary btn-success btn-submit"
                        onClick={() => this.onSubmit()}
                        disabled={!isAgreed || !isAgreeGoogleTerms}
                      >
                        Submit
                      </button>
                    </div>
                    {/* End Body */}

                    <div className="widget-footer">
                      <div className="signature">
                        Powered By{" "}
                        <a
                          href="https://www.entribe.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={EntribeLogo} alt="logo" />
                        </a>
                      </div>
                    </div>
                    {/* End Footer */}
                  </div>
                )}

                {formState === 3 && (
                  <div className="widget-block widget-thankyou">
                    <div className="widget-header">
                      <div className="logo" style={logoStyles}></div>
                    </div>
                    <div className="widget-body team-pixel-body-thankyou">
                      <div className="body-container">
                        <div style={{ padding: "0 6px" }}>
                          <h2>
                            Your submission is complete. Thank you for sharing
                            with us!
                          </h2>
                          <h2 className="space">
                            Thanks for being a member of the growing #teampixel
                            community.
                          </h2>
                          <p>
                            Follow us on social to see more from #teampixel:
                          </p>

                          <ul className="list-unstyled">
                            <li>
                              <div className="social-logo">
                                <img src={instagramLogo} alt="Instagram logo" />
                              </div>
                              <a
                                href="https://www.instagram.com/googlepixel"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                @googlepixel
                              </a>
                            </li>
                            <li>
                              <div className="social-logo twitter">
                                <img src={xLogo} alt="Twitter logo" />
                              </div>
                              <a
                                href="https://twitter.com/madebygoogle"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                @madebygoogle
                              </a>
                            </li>
                            <li>
                              <div className="social-logo">
                                <img src={facebookLogo} alt="Facebook logo" />
                              </div>
                              <a
                                href="https://www.facebook.com/madebygoogle/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://www.facebook.com/madebygoogle/
                              </a>
                            </li>
                            <li>
                              <div className="social-logo">
                                <img src={youtubeLogo} alt="Youtube logo" />
                              </div>
                              <a
                                href="https://www.youtube.com/c/madebygoogle"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://www.youtube.com/c/madebygoogle
                              </a>
                            </li>
                          </ul>
                        </div>
                        <UploadStatusList />
                      </div>
                    </div>
                    <div className="widget-footer">
                      <div className="signature">
                        Powered By{" "}
                        <a
                          href="https://www.entribe.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={EntribeLogo} alt="logo" />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* End Form */}

              <div className="col-lg-5 d-none d-lg-block d-xl-block gallery">
                {this.renderGallery(chunkImages)}
              </div>
              {/* End Gallery */}
            </div>
            <div className="mobile-gallery-container">
              <div className="gallery">
                {this.renderGallery(chunkImages, true)}
              </div>
            </div>
          </div>
          {/* End Upload Form */}
        </div>

        {modalType === MODAL_TYPE.GOOGLE_TERMS && (
          <GoogleTermsModal
            isShowGoogleTerms={modalType === MODAL_TYPE.GOOGLE_TERMS}
            googleTerms={googleTerms}
            handleGoogleTerms={() => this.handleTermsModal("")}
          />
        )}
        {modalType === MODAL_TYPE.STANDARD_TERMS && (
          <StandardTermsModal
            isShowGoogleTerms={modalType === MODAL_TYPE.STANDARD_TERMS}
            handleGoogleTerms={() => this.handleTermsModal("")}
          />
        )}
      </Preload>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createContentRequest: (payload) =>
      dispatch({ type: createContentTypes.CREATE_CONTENT_REQUEST, payload }),
    uploadContentRequest: (payload) =>
      dispatch({ type: uploadContentTypes.UPLOAD_CONTENT_REQUEST, payload }),
    sendEmailRequest: (payload) =>
      dispatch({ type: sendEmailTypes.SEND_EMAIL_REQUEST, payload }),
    getGeoLocationRequest: (payload) =>
      dispatch({ type: getGeolocationTypes.GET_GEOLOCATION_REQUEST, payload }),
    getGeoLocationEnd: () =>
      dispatch({ type: getGeolocationTypes.GET_GEOLOCATION_END }),
    storeDataRequest: (payload) =>
      dispatch({ type: storeDataTypes.STORE_DATA, payload }),
  };
};

const mapStateToProps = (state) => ({
  widgets: state.widgets,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TeamPixel));
